// import Vue from 'vue'
// import Router from 'vue-router'

// Vue.use(Router)

// /* Layout */
// import Layout from '@/layout'

// // 公共路由
// export const constantRoutes = [
//   {
//     path: '/redirect',
//     component: Layout,
//     hidden: true,
//     children: [
//       {
//         path: '/redirect/:path(.*)',
//         component: () => import('@/views/redirect')
//       }
//     ]
//   },
//   {
//     path: '/login',
//     component: () => import('@/views/login'),
//     hidden: true
//   },
//   // {
//   //   path: '/register',
//   //   component: () => import('@/views/register'),
//   //   hidden: true
//   // },
//   {
//     path: '/404',
//     component: () => import('@/views/error/404'),
//     hidden: true
//   },
//   // {
//   //   path: '/401',
//   //   component: () => import('@/views/error/401'),
//   //   hidden: true
//   // },
//   // {
//   //   path: '',
//   //   component: Layout,
//   //   redirect: 'index',
//   //   // redirect: 'index1',
//   //   children: [
//   //     {
//   //       path: 'index',
//   //       // path: 'index1',
//   //       component: () => import('@/views/index copy'),
//   //       // component: () => import('@/views/home/data'),
//   //       name: 'Index',
//   //       // name: 'Index1',
//   //       meta: { title: '首页222', icon: 'dashboard', affix: true }  // 修改后台菜单栏首页
//   //     }
//   //   ]
//   // },
//   {
//     path: '',
//     component: Layout,
//     redirect: 'home/data',
//     // redirect: 'index1',
//     children: [
//       {
//         path: 'home/data',
//         // path: 'index1',
//         // component: () => import('@/views/index copy'),
//         component: () => import('@/views/home/data'),
//         name: 'Index',
//         // name: 'Index1',
//         // meta: { title: '首页222', icon: 'dashboard', affix: true }  // 修改后台菜单栏首页
//         // meta: { title: '数据看板', icon: 'dashboard', affix: true }
//         meta: {title: '数据看板'},
//         hidden: true
//       }
//     ]
//   },
//   {
//     path: '/user',
//     component: Layout,
//     hidden: true,
//     redirect: 'noredirect',
//     children: [
//       {
//         path: 'profile',
//         component: () => import('@/views/system/user/profile/index'),
//         name: 'Profile',
//         meta: { title: '个人中心', icon: 'user' }
//       }
//     ]
//   },
//   {
//     path: '/vip',
//     component: Layout,
//     redirect: '/vip/member',
//     name: 'Vip',
//     meta: { title: '会员', icon: 'el-icon-organization-solid'},
//     hidden: true,
//     children: [
//       {
//         path: 'member',
//         name: 'Members',
//         component: () => import('@/views/vip/member/index'),
//         meta: { title: '会员管理', noCache: true, activeMenu: '/vip/member' },
//         hidden: true
//       },
//       {
//         // path: 'member/details/:number(\\d+)',
//         path: 'member/details/',
//         name: 'Details',
//         component: () => import('@/views/vip/member/details/index'),
//         meta: { title: '组织详情', noCache: true, activeMenu: '/vip/member/details' },
//         hidden: true
//       },
//       {
//         path: 'member/project/',
//         name: 'Projects',
//         component: () => import('@/views/vip/member/project/index'),
//         meta: { title: '项目列表', noCache: true, activeMenu: '/vip/member/project' },
//         hidden: true
//       }
//     ]
//   },
  
//   // {
//   //   path: '/vip',
//   //   component: Layout,
//   //   redirect: '/vip/member',
//   //   name: 'Member',
//   //   meta: { title: '组织管理', icon: 'el-icon-organization-solid'},
//   //   children: [
//   //     {
//   //       path: 'member/project/',
//   //       name: 'Projects',
//   //       component: () => import('@/views/vip/member/project/index'),
//   //       meta: { title: '项目详情', noCache: true, activeMenu: '/vip/member' },
//   //       hidden: true
//   //     }
//   //   ]
//   // },
//   {
//     path: '/news',
//     component: Layout,
//     redirect: '/news/manage',
//     // redirect: '/news/manage',
//     name: 'News',
//     meta: { title: '资讯动态', icon: 'el-icon-organization-solid'},
//     hidden: true,
//     children: [
//       {
//         path: 'manage',
//         name: 'Manage',
//         component: () => import('@/views/news/manage/index'),
//         meta: { title: '资讯动态管理'},
//         hidden: true
//       },
//       {
//         path: 'manage/details',
//         name: 'newsDetails',
//         component: () => import('@/views/news/manage/details/index'),
//         meta: { title: '资讯详情'},
//         hidden: true
//       } 
//     ]
//   },
//   //  /cooperation/meeting/details/
//   {
//     path: '/cooperation',
//     component: Layout,
//     redirect: '/cooperation/meeting',
//     // redirect: '/news/manage',
//     name: 'Cooperation',
//     meta: { title: '校企合作'},
//     hidden: true,
//     children: [
//       {
//         path: 'meeting',
//         name: 'Meeting',
//         component: () => import('@/views/cooperation/meeting/index'),
//         meta: { title: '校企会管理'},
//         hidden: true
//       },
//       {
//         // path: 'meeting/details:number(\\d+)',
//         path: 'meeting/details',
//         name: 'meetingDetails',
//         component: () => import('@/views/cooperation/meeting/details/index'),
//         meta: { title: '校企会详情', noCache: true, activeMenu: '/cooperation/meeting/details'},
//         hidden: true
//       },
//       {
//         // path: 'meeting/details:number(\\d+)',
//         path: 'meeting/apply',
//         name: 'Apply',
//         component: () => import('@/views/cooperation/meeting/apply/index'),
//         meta: { title: '报名管理', noCache: true, activeMenu: '/cooperation/meeting/apply'},
//         hidden: true
//       },
//       // {
//       //   // path: 'meeting/details:number(\\d+)',
//       //   path: 'meeting/apply/details',
//       //   name: 'applyDetails',
//       //   component: () => import('@/views/vip/member/details/index'),
//       //   meta: { title: '组织详情', noCache: true, activeMenu: '/vip/member/details'},
//       //   hidden: true
//       // },
//       {
//         path: 'project',
//         name: 'Project',
//         component: () => import('@/views/cooperation/project/index'),
//         meta: { title: '校企项目管理'},
//         hidden: true
//       },
//       {
//         path: 'project/details',
//         name: 'projectDetails',
//         component: () => import('@/views/cooperation/project/details/index'),
//         meta: { title: '项目详情', noCache: true, activeMenu: '/cooperation/project/details'},
//         hidden: true
//       }
//     ]
//   },
//   {
//     path: '/advertisement',
//     component: Layout,
//     redirect: '/advertisement/manage',
//     name: 'Advertisement',
//     meta: {title: '广告'},
//     hidden: true,
//     children: [
//       {
//         path: 'manage',
//         name: 'advertisementManage',
//         component: () => import('@/views/advertisement/manage/index'),
//         meta: {title: '广告管理', noCache: true, activeMenu: '/advertisement/manage'},
//         hidden: true
//       },
//       {
//         path: 'manage/details',
//         name: 'advertisementDetails',
//         component: () => import('@/views/advertisement/manage/details/index'),
//         meta: {title: '详情', noCache: true, activeMenu: '/advertisement/manage/details'},
//         hidden: true
//       },
//       {
//         path: 'video',
//         name: 'Video',
//         component: () => import('@/views/advertisement/video/index'),
//         meta: {title: '视频广告管理', noCache: true, activeMenu: '/advertisement/video'}
//       },
//       {
//         path: 'video/details',
//         name: 'VideoDetails',
//         component: () => import('@/views/advertisement/video/details/index'),
//         meta: {title: '视频广告管理', noCache: true, activeMenu: '/advertisement/video/details'}
//       }
//     ]
//   },
//   {
//     path: '/site',
//     component: Layout,
//     redirect: '/site/error',
//     name: 'Site',
//     meta: {title: '网站管理'},
//     hidden: true,
//     children: [
//       {
//         path: 'error',
//         name: 'Error',
//         component: () =>import('@/views/site/error/index'),
//         meta: {title: '报错管理', noCache: true, activeMenu: '/views/site/error'}
//       },
//       {
//         path: 'error/details',
//         name: 'ErrorDetails',
//         component: () =>import('@/views/site/error/details/index'),
//         meta: {title: '报错管理', noCache: true, activeMenu: '/views/site/error/details'}
//       },
//       {
//         path: 'position',
//         name: 'Position',
//         component: () =>import('@/views/site/position/index'),
//         meta: {title: '广告位管理', noCache: true, activeMenu: '/views/site/position'}
//       },
//       {
//         path: 'manage',
//         name: 'SiteManage',
//         component: () =>import('@/views/site/manage/index'),
//         meta: {title: '网站管理', noCache: true, activeMenu: '/views/site/manage'}
//       }
//     ]
//   },
//   {
//     path: '/cost',
//     component: Layout,
//     redirect: '/cost/deal',
//     name: 'Cost',
//     meta: {title: '费用管理'},
//     hidden: true,
//     children: [
//       {
//         path: 'finance',
//         name: 'Finance',
//         component: () =>import('@/views/cost/finance/index'),
//         meta: {title: '财务管理', noCache: true, activeMenu: '/views/cost/finance'}
//       },
//       {
//         path: 'finance/details',
//         name: 'FinanceDetails',
//         component: () =>import('@/views/cost/finance/details/index'),
//         meta: {title: '交易详情', noCache: true, activeMenu: '/views/cost/finance/details'}
//       },
//       {
//         path: 'expense',
//         name: 'Expense',
//         component: () =>import('@/views/cost/expense/index'),
//         meta: {title: '费用设置', noCache: true, activeMenu: '/views/cost/expense'}
//       },
//       {
//         path: 'expense/log',
//         name: 'ExpenseLog',
//         component: () =>import('@/views/cost/expense/log/index'),
//         meta: {title: '操作日志', noCache: true, activeMenu: '/views/cost/expense/log'}
//       },
//       {
//         path: 'cashgift',
//         name: 'Cashgift',
//         component: () =>import('@/views/cost/cashgift/index'),
//         meta: {title: '会员礼金管理', noCache: true, activeMenu: '/views/cost/cashgift'}
//       }
//     ]
//   },
//   {
//     path: '/home',
//     component: Layout,
//     redirect: '/home/data',
//     name: 'Home',
//     meta: {title: '数据看板'},
//     hidden: true,
//     children: [
//       {
//         path: 'data',
//         name: 'Data',
//         component: () =>import('@/views/home/data/index'),
//         meta: {title: '数据看板', noCache: true, activeMenu: '/views/home/data'}
//       }
//     ]
//   }
//   // {
//   //   path: '/test',
//   //   component: Layout,
//   //   redirect: 'noredirect',
//   //   name: 'Test',
//   //   meta: { title: 'test1', icon: 'el-icon-organization-solid'},
//     // children: [
//     //   {
//     //     path: 'manage',
//     //     name: 'Manage',
//     //     component: () => import('@/views/news/manage/index'),
//     //     meta: { title: '资讯动态管理'},
//     //     hidden: true
//     //   }
//     // ]
//   // }
// ]

// // 动态路由，基于用户权限动态去加载
// export const dynamicRoutes = [
//   {
//     path: '/system/user-auth',
//     component: Layout,
//     hidden: true,
//     permissions: ['system:user:edit'],
//     children: [
//       {
//         path: 'role/:userId(\\d+)',
//         component: () => import('@/views/system/user/authRole'),
//         name: 'AuthRole',
//         meta: { title: '分配角色', activeMenu: '/system/user' }
//       }
//     ]
//   },
//   {
//     path: '/system/role-auth',
//     component: Layout,
//     hidden: true,
//     permissions: ['system:role:edit'],
//     children: [
//       {
//         path: 'user/:roleId(\\d+)',
//         component: () => import('@/views/system/role/authUser'),
//         name: 'AuthUser',
//         meta: { title: '分配用户', activeMenu: '/system/role' }
//       }
//     ]
//   },
//   {
//     path: '/system/dict-data',
//     component: Layout,
//     hidden: true,
//     permissions: ['system:dict:list'],
//     children: [
//       {
//         path: 'index/:dictId(\\d+)',
//         component: () => import('@/views/system/dict/data'),
//         name: 'Data',
//         meta: { title: '字典数据', activeMenu: '/system/dict' }
//       }
//     ]
//   },
//   {
//     path: '/monitor/job-log',
//     component: Layout,
//     hidden: true,
//     permissions: ['monitor:job:list'],
//     children: [
//       {
//         path: 'index/:jobId(\\d+)',
//         component: () => import('@/views/monitor/job/log'),
//         name: 'JobLog',
//         meta: { title: '调度日志', activeMenu: '/monitor/job' }
//       }
//     ]
//   },
//   {
//     path: '/tool/gen-edit',
//     component: Layout,
//     hidden: true,
//     permissions: ['tool:gen:edit'],
//     children: [
//       {
//         path: 'index/:tableId(\\d+)',
//         component: () => import('@/views/tool/gen/editTable'),
//         name: 'GenEdit',
//         meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
//       }
//     ]
//   }
// ]

// // 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// let routerReplace = Router.prototype.replace;
// // push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(err => err)
// }
// // replace
// Router.prototype.replace = function push(location) {
//   return routerReplace.call(this, location).catch(err => err)
// }

// export default new Router({
//   mode: 'history', // 去掉url中的#
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes
// })

import Vue from "vue";
import VueRouter from "vue-router";
// import index from "../views/index.vue";
// import jituangaikuo from "../views/jituangaikuo.vue";
import allianceGeneralization from "../views/allianceGeneralization.vue";
import newss from "@/views/newss.vue"
// import keji from "../views/keji.vue";
// import youjiao from "../views/youjiao.vue";
// import jinrong from "../views/jinrong.vue";
// import zhijiao from "../views/zhijiao.vue";
// import dianshang from "../views/dianshang.vue";
// import news from "../views/news.vue";
// import GroupNews from "../views/News/GroupNews.vue";
// import MediaCoverage from "../views/News/MediaCoverage.vue";
// import TradeNews from "../views/News/TradeNews.vue";
// import newsDetail from "../views/newsxiangqing.vue";
import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
import MediaCoverage0 from "../views/NewsDetals/MediaCoverage0.vue";
import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
// import zhaopinxinxi0 from "../views/zhaopinxinxi0.vue";
// import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
import lianxi from "../views/lianxiwomen.vue";
// import zhaopinxiangqing0_1 from "../views/Recruitment-details/zhaopinxiangqing0-1.vue";
// import zhaopinxiangqing0_0 from "../views/Recruitment-details/zhaopinxiangqing0-0.vue";
// import zhaopinxiangqing1_0 from "../views/Recruitment-details/zhaopinxiangqing1-0.vue";
// import zhaopinxiangqing1_1 from "../views/Recruitment-details/zhaopinxiangqing1-1.vue";

// 报错解决
// 获取原型对象push函数
// const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
/* VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
 */
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: "login",
		component: () => import('@/views/login')	// 这种导入方式是静态导入，打包的时候会将所有组件的js代码打成一个非常大的js文件
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('@/views/agreement'),
		meta: {
			title: "网站使用协议书",
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/register2'),
		meta: {
			title: "会员注册",
		},
	},
	{
		path: '/registerState',
		name: '/registerState',
		component: () => import('@/views/registerState'),
		meta: {
			title: "注册状态",
		},
	},
	{
		path: '/member',
		name: '/member',
		component: () => import('@/views/member/index'),
		meta: {
			title: "会员",
		},
	},
	{
		path: '/member/details',
		name: '/memberDetails',
		component: () => import('@/views/member/details/index'),
		meta: {
			title: "会员详情",
		},
	},
	{
		path: '/news',
		// name: 'n'
		component: () => import('@/views/news1/index'),
		meta: {
			title: "资讯",
		},
	},
	{
		path: '/news/details',
		// name: 'n'
		component: () => import('@/views/news1/details/index'),
		meta: {
			title: "资讯详情",
		},
	},
	{
		path: '/meeting',
		// name: 'n'
		component: () => import('@/views/meeting/index'),
		meta: {
			title: "校企会",
		},
	},
	{
		path: '/meeting/details',
		component: () => import('@/views/meeting/details/index'),
		meta: {
			title: "校企会详情",
		},
	},
	{
		path: '/project',
		// name: 'n'
		component: () => import('@/views/project/index'),
		meta: {
			title: "会员项目",
		},
	},
	{
		path: '/project/details',
		// name: 'n'
		component: () => import('@/views/project/details/index'),
		meta: {
			title: "会员项目详情",
		},
	},
	{
		path: '/member/memberInfo',
		component: () => import('@/views/member/memberInfo/index'),
		meta: {
			title: "会员信息",
		},
	},
	{
		path: '/member/resetPwd',
		component: () => import('@/views/member/resetPwd/index'),
		meta: {
			title: "修改密码",
		},
	},
	{
		path: '/member/resetMail',
		component: () => import('@/views/member/resetMail/index'),
		meta: {
			title: "修改邮箱",
		},
	},
	{
		path: '/member/resetPhone',
		component: () => import('@/views/member/resetPhone/index'),
		meta: {
			title: "修改手机号",
		},
	},
	{
		path: '/member/myPosted',
		component: () => import('@/views/member/myPosted/index'),
		meta: {
			title: "我发布的",
		},
	},
	{
		path: '/member/myPosted/publish',
		component: () => import('@/views/member/myPosted/publish/index'),
		meta: {
			title: "发布项目",
		},
	},
	{
		path: '/member/myParticipated',
		component: () => import('@/views/member/myParticipated/index'),
		meta: {
			title: "我参与的",
		},
	},
	{
		path: '/member/finance',
		component: () => import('@/views/member/finance/index'),
		meta: {
			title: "财务管理",
		},
	},
	{
		path: '/member/finance/details',
		component: () => import('@/views/member/finance/details/index'),
		meta: {
			title: "交易详情",
		},
	},
	{
		path: "/",
		// name: "index1",
		name: "index",
		// component: index,
		// component: () => import('@/views/index1'),		// 这种导入方式是动态导入，打包的时候不会将所有代码打包到一起，让他按需加载
		component: () => import('@/views/index'),		// 这种导入方式是动态导入，打包的时候不会将所有代码打包到一起，让他按需加载
		meta: {
			title: "首页",
		},
	},
	{
		// path: "/jituangaikuo",
		path: "/allianceGeneralization",
		// name: "jituangaikuo",
		name: "allianceGeneralization",
		component: allianceGeneralization,
		meta: {
			// title: "集团概况111",
			title: "联盟概况",
		},
	},
	{
		path: "/newss",
		name: "newss",
		component: newss,
		meta: {
			title: "资讯动态",
		},
	},
	{
		path: "/college",
		name: "college",
		component: () => import('@/views/college'),
		meta: {
			title: "院校库",
		},
	},
	{
		path: "/enterprise",
		name: "enterprise",
		component: () => import('@/views/enterprise'),
		meta: {
			title: "企业库",
		},
	},
	{
		path: "/cooperation",
		name: "cooperation",
		component: () => import('@/views/cooperation'),
		meta: {
			title: "校企合作",
		}
	},
	{
		path: "/collegeProject",
		name: "collegeProject",
		component: () => import('@/views/collegeProject'),
		meta: {
			title: "院校项目",
		}
	},
	{
		path: "/enterpriseProject",
		name: "enterpriseProject",
		component: () => import('@/views/enterpriseProject'),
		meta: {
			title: "企业项目",
		}
	},
	{
		path: "/apply",
		name: "apply",
		component: () => import('@/views/apply'),
		meta: {
			title: "报名",
		}
	},
	{
		path: "/meetingApply",
		name: "meetingApply",
		component: () => import('@/views/meetingApply'),
		meta: {
			title: "校企会列表",
		}
	},
	{
		path: "/siteError",
		name: "siteError",
		component: () => import('@/views/siteError'),
		meta: {
			title: "网站报错",
		}
	},
	// {
	// 	path: "/"
	// }
	// {
	// 	path: "/keji",
	// 	name: "keji",
	// 	component: keji,
	// 	meta: {
	// 		title: "科技",
	// 	},
	// },
	// {
	// 	path: "/youjiao",
	// 	name: "youjiao",
	// 	component: youjiao,
	// 	meta: {
	// 		title: "幼教",
	// 	},
	// },
	// {
	// 	path: "/jinrong",
	// 	name: "jinrong",
	// 	component: jinrong,
	// 	meta: {
	// 		title: "金融",
	// 	},
	// },
	// {
	// 	path: "/zhijiao",
	// 	name: "zhijiao",
	// 	component: zhijiao,
	// 	meta: {
	// 		title: "执教",
	// 	},
	// },
	// {
	// 	path: "/dianshang",
	// 	name: "dianshang",
	// 	component: dianshang,
	// 	meta: {
	// 		title: "电商",
	// 	},
	// },
	// {
	// 	path: "/xinwen",
	// 	name: "xinwen",
	// 	component: news,
	// 	children: [
	// 		{
	// 			path: "",
	// 			component: GroupNews,
	// 			name: "GroupNews",
	// 			meta: {
	// 				title: "集团新闻",
	// 			},
	// 		},
	// 		{
	// 			path: "MediaCoverage",
	// 			component: MediaCoverage,
	// 			name: "MediaCoverage",
	// 			meta: {
	// 				title: "媒体报道",
	// 			},
	// 		},
	// 		{
	// 			path: "TradeNews",
	// 			name: "TradeNews",
	// 			component: TradeNews,
	// 			meta: {
	// 				title: "行业资讯",
	// 			},
	// 		},
	// 	],
	// 	meta: {
	// 		title: "集团新闻",
	// 	},
	// },
	// {
	// 	path: "/newsxiangqing0",
	// 	name: "newsDetal",
	// 	component: newsDetail,
	// 	meta: {
	// 		title: "新闻详情",
	// 	},
	// },
	// {
	// 	path: "/newsxiangqing1",
	// 	name: "newsDetal1",
	// 	component: newsDetail1,
	// 	meta: {
	// 		title: "新闻详情1",
	// 	},
	// },
	// {
	// 	path: "/newsxiangqing2",
	// 	name: "newsDetal2",
	// 	component: newsDetail2,
	// 	meta: {
	// 		title: "新闻详情2",
	// 	},
	// },
	// {
	// 	path: "/MediaCoverage0",
	// 	name: "MediaCoverage0",
	// 	component: MediaCoverage0,
	// 	meta: {
	// 		title: "媒体报道0",
	// 	},
	// },
	// {
	// 	path: "/MediaCoverage1",
	// 	name: "MediaCoverage1",
	// 	component: MediaCoverage1,
	// 	meta: {
	// 		title: "媒体报道1",
	// 	},
	// },
	// {
	// 	path: "/TradeNews0",
	// 	name: "TradeNews0",
	// 	component: TradeNews0,
	// 	meta: {
	// 		title: "行业资讯0",
	// 	},
	// },
	// {
	// 	path: "/TradeNews1",
	// 	name: "TradeNews1",
	// 	component: TradeNews1,
	// 	meta: {
	// 		title: "行业资讯1",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxinxi0",
	// 	name: "zhaopinxinxi0",
	// 	component: zhaopinxinxi0,
	// 	meta: {
	// 		title: "招聘信息0",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxinxi1",
	// 	name: "zhaopinxinxi1",
	// 	component: zhaopinxinxi1,
	// 	meta: {
	// 		title: "招聘信息1",
	// 	},
	// },
	// {
	// 	path: "/lianxi",
	// 	name: "lianxi",
	// 	component: lianxi,
	// 	meta: {
	// 		title: "联系我们",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxiangqing0_0",
	// 	name: "zhaopinxiangqing0_0",
	// 	component: zhaopinxiangqing0_0,
	// 	meta: {
	// 		title: "招聘详情0-0",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxiangqing0_1",
	// 	name: "zhaopinxiangqing0_1",
	// 	component: zhaopinxiangqing0_1,
	// 	meta: {
	// 		title: "招聘详情0-1",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxiangqing1_0",
	// 	name: "zhaopinxiangqing1_0",
	// 	component: zhaopinxiangqing1_0,
	// 	meta: {
	// 		title: "招聘详情1-0",
	// 	},
	// },
	// {
	// 	path: "/zhaopinxiangqing1_1",
	// 	name: "zhaopinxiangqing1_1",
	// 	component: zhaopinxiangqing1_1,
	// 	meta: {
	// 		title: "招聘详情1-1",
	// 	},
	// },
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
// const defaultTitle = "默认 title";
// router.beforeEach((to, from, next) => {
// 	document.title = to.meta.title ? to.meta.title : defaultTitle;
// 	next();
// });
