import Cookies from 'js-cookie'

const TokenKey = 'Web-Token'
const UserIdKey = 'UserIdKey'
const UserPhoneKey = 'UserPhoneKey'
const MemberNameKey = 'MemberNameKey'
const UserStateKey = 'UserStateKey'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserId() {
  return Cookies.get(UserIdKey)
}

export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId)
}

export function removeUserId() {
  return Cookies.remove(UserIdKey)
}

export function getUserState() {
  return Cookies.get(UserStateKey)
}

export function setUserState(userState) {
  return Cookies.set(UserStateKey, userState)
}

export function removeUserState() {
  return Cookies.remove(UserStateKey)
}

export function getUserPhone() {
  return Cookies.get(UserPhoneKey)
}

export function setUserPhone(phone) {
  return Cookies.set(UserPhoneKey, phone)
}

export function removeUserPhone() {
  return Cookies.remove(UserPhoneKey)
}

export function getMemberName() {
  return Cookies.get(MemberNameKey)
}

export function setMemberName(name) {
  return Cookies.set(MemberNameKey, name)
}

export function removeMemberName() {
  return Cookies.remove(MemberNameKey)
}