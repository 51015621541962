import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 企业登录方法
// export function login2(phone, password, code, uuid) {
// 密码登录
export function login2(phone, password) {
  const data = {
    phone,
    password,
    // code,
    // uuid
  }
  return request({
    url: '/organization/passwordLogin',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 验证码登录
export function login3(phone, code) {
  const data = {
    phone,
    code,
    // code,
    // uuid
  }
  return request({
    url: '/organization/codeLogin',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/kaptcha',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })

// 获取手机号登录验证码
// export function sendMsg(data) {
//   return request({
//     url: '/organization/sendMsg',
//     headers: {
//       isToken: false
//     },
//     method: 'post',
//     data: data
//   })
// }
}