<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<!-- <nav2></nav2> -->
		<div class="flex align-center around">
			<!-- <div class="logo">
				<div style="height: 150px; width: 150px">
					<img style="height: 150px; width: 150px" :src="logoimg" class="inline-img" />
				</div>
				<div style="text-align: center; cursor: pointer;" id="i2" @click="handleIndex">
					职校联盟
				</div>
			</div> -->
			<div class="logo" style="display: flex; align-items: center;">
				<div style="height: 150px; width: 150px">
					<img style="height: 150px; width: 150px" :src="logoimg" />
				</div>
				<div style=" cursor: pointer; font-weight: 900; font-size: x-large;" id="i2" @click="handleIndex">
					职校联盟
				</div>
			</div>
			<div class="flex align-center">
				<div class="label" v-for="(item, index) in navList" :key="index" @click="labelClick(index, item.value)" style="cursor: pointer;">
					<div class="label-text" :style="labelIndex == index ? 'color:#333;' : ''">{{ item.label }}</div>
					<div class="label-index" v-if="labelIndex == index"></div>
				</div>
			</div>
			<div v-if="phone === null || phone === undefined" style="cursor: pointer;" @click="funyuming('/login')">会员登录/注册</div>
			<div v-if="phone">
				<el-dropdown v-if="phone" style="margin-top: -10px;">
					<!-- <span class="el-dropdown-link">
						{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span> -->
					<span v-if="memberName !== null && memberName !== undefined && memberName !== 'null' " class="el-dropdown-link">
						{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<span v-else class="el-dropdown-link">
						{{ phone }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="funyuming('/member/memberInfo')">会员信息</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/myPosted')">我发布的</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/myParticipated')">我参与的</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/finance')">财务管理</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/logout')">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- <div style="display: flex; margin-left: 15%; margin-right: 10%;">
			<p style="flex: 1; margin-top: 15px;">欢迎进入职校联盟官网！</p>
			<div style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
				<el-input 
					v-model="queryParams.name"
					placeholder="请输入关键字"
					@keyup.enter.native="handleQuery"
					size="small"
					style="width: 170px;"
				></el-input>
				<el-select v-model="searchType" placeholder="请选择搜索类型" size="small" style="width: 100px; margin-left: 10px;">
					<el-option v-for="item in searchTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<router-link v-if="searchType === '1'" :to="{ path: '/member', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '2'" :to="{ path: '/meeting', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '3'" :to="{ path: '/project', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '4'" :to="{ path: '/news', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px;">搜索</el-button>
				</router-link>
			</div>

			<div style="flex: 1; margin-top: 15px;">
				<p style="text-align: right;">服务热线：400123456</p>
			</div>
		</div> -->

		<div class="align-center around" style="
		  display: flex;
		  background-color: rgb(236,239,247);
		  padding: 20px;
		  margin-top: 20px;
		">
			<p style="">欢迎进入职校联盟官网！</p>
			<div style="text-align: center">
				<!-- <el-input style="width: 170px; margin-left: 50%"></el-input> -->
				<el-input v-model="queryParams.name" placeholder="请输入关键字" @keyup.enter.native="handleQuery" size="small"
					style="width: 370px"></el-input>
				<el-select v-model="searchType" placeholder="请选择搜索类型" size="small"
					style="width: 150px; margin-left: 10px">
					<el-option v-for="item in searchTypes" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<router-link v-if="searchType === '1'" :to="{ path: '/member', query: { name: queryParams.name, labelIndex: labelIndex } }">
					<el-button style="margin-left: 10px" type="primary" icon="el-icon-search" size="mini">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '2'" :to="{ path: '/meeting', query: { name: queryParams.name, labelIndex: labelIndex } }">
					<el-button style="margin-left: 10px" type="primary" icon="el-icon-search" size="mini">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '3'" :to="{ path: '/project', query: { name: queryParams.name, labelIndex: labelIndex } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '4'" :to="{ path: '/news', query: { name: queryParams.name, labelIndex: labelIndex } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px">搜索</el-button>
				</router-link>
			</div>
			<div>
				<p>服务热线：{{ phoneNumber }}</p>
			</div>
		</div>
		<!-- <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1" ></banner2> -->
		<!-- <banner2 :bannerImg="Img1" ></banner2> -->
		<banner2 :bannerImg="homeBanner" ></banner2>
		<!-- 面包屑 -->
		<!-- <div class="Crumbs">
			<div>当前位置：首页><span>新闻</span></div>
		</div> -->
		<!-- 按钮板块 -->
		<!-- <div class="btn">
			<div class="btn_cont">
				<div
					:class="active === item.url ? 'active' : ''"
					v-for="(item, index) in newsList"
					:key="index"
					@click="NewsDetails(item.url)"
				>
					{{ item.title }}
				</div>
			</div>
		</div> -->
		<!-- 新闻内容 -->
		<!-- <router-view></router-view> -->
		<h2>资讯动态</h2>
		<div class="content">
			<div class="left">
				<!-- <img src="@/assets/img/ABUIABACGAAgvraU9QUomKOo1wMw9AM49AM.jpg" alt="" height="200px"> -->
				<div class="block" style="width: 338px;">
					<el-carousel indicator-position="none" arrow="never" height="220px">
						<el-carousel-item v-for="(item, index) in newsList2" :key="index">
							<!-- <h3 class="small">{{ item.image }}</h3> -->
							<el-image :src="item.image" id="s1" style="width: 338px; height: 220px;" fit="scale-down"  @click="handleNewsDetails(item.id)"></el-image>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<!-- <div class="middle">
				<p>第十八届校企合作会圆满结束</p>
				<p>第十九届北京校企合作会火热报名中</p>
				<p>李强主持召开国务院常务会议 确定国务院2023..</p>
				<p>国家发展改革委等部门关于印发《职业教育产教融合...</p>
				<p>教育部 天津市人民政府印发《关于探索现代职业教...</p>
			</div>
			<div class="right">
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
			</div> -->
			<el-table :show-header="false" :data="newsList2" style="margin-right: 200px; height: 270px;">
				<el-table-column prop="title" min-width="70%">
					<template slot-scope="scope">
						<span id="s1" @click="handleNewsDetails(scope.row.id)">{{ scope.row.title }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" min-width="30%"></el-table-column>
			</el-table>
		</div>
		<div class="list">
			<!-- <div class="left"> -->
				<!-- <p>第十八届校企合作会圆满结束</p>
				<p>第十九届北京校企合作会火热报名中</p>
				<p>李强主持召开国务院常务会议 确定国务院2023..</p>
				<p>国家发展改革委等部门关于印发《职业教育产教融合...</p>
				<p>教育部 天津市人民政府印发《关于探索现代职业教...</p>
				<p>教育部 天津市人民政府印发《关于探索现代职业教...</p>
				<p>教育部 天津市人民政府印发《关于探索现代职业教...</p>
				<p>教育部 天津市人民政府印发《关于探索现代职业教...</p> -->
				<!-- <ul>
					<li v-for="(item, index) in this.newsList">{{ item.title }}</li>
				</ul> -->
				<!-- <el-table :data="newsList">
					<el-table-column prop="title"></el-table-column>
					<el-table-column prop="createTime"></el-table-column>
				</el-table>
			</div> -->
			<!-- <div class="middle">
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
				<p>654次浏览</p>
			</div>
			<div class="right">
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
				<p>2023年11月22日</p>
			</div> -->
			<el-table :data="newsList" id = "table2">
				<el-table-column label="标题" prop="title" min-width="50%">
					<template slot-scope="scope">
						<span id="s1" @click="handleNewsDetails(scope.row.id)">{{ scope.row.title }}</span>
					</template>
				</el-table-column>
				<el-table-column label="浏览量" prop="browseCount" min-width="25%">
					<template slot-scope="scope">
						{{ scope.row.browseCount }}次浏览
					</template>
				</el-table-column>
				<el-table-column label="发布时间" prop="createTime" min-width="25%"></el-table-column>
			</el-table>
		</div>
		<!-- <pagination
		v-show="total>0"
		:total="total"
		:page.sync="queryParams.pageNum"
		:limit.sync="queryParams.pageSize"
		@pagination="getList"
		/> -->
		<!-- <el-pagination
			class = "pagination"
			background
			@current-change = "handleCurrentChange"
			:current-page.sync = "queryParams.pageNum"
			:page-size = "queryParams.pageSize"
			layout = "prev, pager, next, jumper"
			:total = "total"
			:hide-on-single-page = "singlePage"
			v-scroll-to = "{ element: '.news-container',duration: 300, easing: 'ease',offset: -40  }">
		</el-pagination> -->
		<el-pagination
			style="margin-top: 22px;margin-bottom: 15px;text-align: center;"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="queryParams.pageNum"
			:page-sizes="[8]"
			:page-size="queryParams.pageSize"
			layout="total, prev, pager, next, jumper"
			:total="total" id="pagination">
		</el-pagination>
		<!-- <el-pagination
			:background="background"
			:current-page.sync="currentPage"
			:page-size.sync="pageSize"
			:layout="layout"
			:page-sizes="pageSizes"
			:pager-count="pagerCount"
			:total="total"
			v-bind="$attrs"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		/> -->
		<!-- 翻页列表 -->
		<!-- <div class="Pages">
      <div class="Pages_cont">
        <ul>
          <li>《</li>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li>...</li>
          <li>20</li>
          <li>》</li>
        </ul>
      </div>
    </div> -->
		<a style="position: fixed; color: black; bottom: 20px; right: 20px;" href="#">
			<div style="width: 100px; text-align: center;">
				<img src="@/assets/img/backTop.png" alt="" width="50" height="50">
				<p>返回顶部</p>
			</div>
		</a>
		<footer2></footer2>
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import {listNews} from "@/api/news/manage"
// import { Pagination } from '@/components/Pagination'
// import Pagination from '@/components/Pagination/index'
// import { selectNewsList } from "@/api/news/manage";
import axios from "axios";
import { getUserPhone, getMemberName, getUserId, removeUserPhone, removeToken, removeMemberName, removeUserId, removeUserState } from '@/utils/auth'

export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		// Pagination
	},
	data() {
		return {
			// h1: "集团新闻",
			// p1: "更多最新精彩资讯，感受企业魅力",
			// active: "GroupNews",
			// newsList: [
			// 	{
			// 		title: "集团新闻",
			// 		url: "GroupNews",
			// 	},
			// 	{
			// 		title: "媒体报道",
			// 		url: "MediaCoverage",
			// 	},
			// 	{
			// 		title: "行业资讯",
			// 		url: "TradeNews",
			// 	},
			// ],
			Img1: require("../assets/img/keji/矿山banner1.png"),
			queryParams: {
				pageNum: 1,
				pageSize: 8,
				name: null
			},
			searchType: "4",
			searchTypes: [
				// {value: '1', label: '院校'},
				// {value: '2', label: '企业'},
				{value: '1', label: '会员'},
				// {value: '3', label: '校企会'},
				{value: '2', label: '校企会'},
				// {value: '4', label: '院校项目'},
				// {value: '5', label: '企业项目'},
				{value: '3', label: '项目'},
				// {value: '6', label: '资讯动态'}
				{value: '4', label: '资讯动态'}
			],
			total: 0,
			// 当前页 默认是第一页
			currentPage: 1,
			newsList: [],
			newsList2: [],
			labelIndex: 2,
			navList: [
				{ label: "首页", value: "/", },
				{ label: "联盟概况", value: "/allianceGeneralization", },
				{ label: "资讯动态", value: "/newss", },
				{ label: "院校库", value: "/college", },
				{ label: "企业库", value: "/enterprise", },
				{ label: "校企合作", value: "/cooperation", }
			],
			logoimg: require("../assets/img/ABUIABACGAAgvraU9QUomKOo1wMw9AM49AM.jpg"),
			homeBanner: localStorage.getItem('homeBanner'),
			phone: getUserPhone(),
			memberName: sessionStorage.getItem('memberName'),
			phoneNumber: localStorage.getItem('phoneNumber'),
		};
	},
	created() {
		// this.getList();
		// this.getList2();	//获取最新的五条新闻
		this.getList3();
		this.getList4();	//获取最新的五条新闻
	},

	// watch: {
	//   $route(now, old) {
	//     // now: 变化之后的数据
	//     // old: 变化之前的数据
	//     let url = now.name;
	//     console.log(url);
	//     console.log(now, old);
	//     if (url.includes("TradeNews")) {
	//       this.active == "TradeNews";
	//     }
	//   },
	//   immediate: true,
	// },
	// watch: {
	// 	$route: {
	// 		// 值发生变化时的回调函数
	// 		handler(now) {
	// 			//..... // now: 变化之后的数据
	// 			// old: 变化之前的数据
	// 			let url = now.name;

	// 			// console.log(url);
	// 			// console.log(now, old);
	// 			if (url.includes("TradeNews")) {
	// 				this.active = "TradeNews";
	// 			} else if (url.includes("GroupNews")) {
	// 				this.active = "GroupNews";
	// 			} else {
	// 				this.active = "MediaCoverage";
	// 			}
	// 		},
	// 		immediate: true, //立即执行
	// 	},
	// },
	methods: {
		// 回车搜索
		handleQuery() {
			if(this.searchType === "1") {
				this.$router.push({path: '/member', query: {name: this.queryParams.name, labelIndex: this.labelIndex}})
			}
			if(this.searchType === '2'){
                this.$router.push({ path: '/meeting', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
            if(this.searchType === '3'){
                this.$router.push({ path: '/project', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
            if(this.searchType === '4'){
                this.$router.push({ path: '/news', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
		},
		// 改变每页大小的回调
		handleSizeChange (val) {
			this.queryParams.pageSize = val
		
			this.newsList = this.getList()
		},
		NewsDetails(url) {
			this.$router.push({
				name: url,
			});
			// this.active = url;
		},
		// 查询资讯动态列表
		// async getList() {
		// 	this.newsList = []
		// 	const {data: res} = await this.$http.get('http://101.200.80.133:8081/prod-api/news/selectNewsList', {params: this.queryParams})
		// 	if (res.status !== 200) {
		// 		this.newsList = []
		// 	} else {
		// 		this.newsList = res.rows;
		// 	}
		// }
		getList() {
			const that = this;
			var config = {
				method: "get",
				// url: "http://101.200.80.133:8081/prod-api/news/selectNewsList2",
				// url: "http://localhost:8080/news/selectNewsList2?pageNum=" + this.queryParams.pageNum + "&pageSize=" + this.queryParams.pageSize,
				// url: `http://localhost:8080/news/selectNewsList?pageNum=${this.queryParams.pageNum}&pageSize=${this.queryParams.pageSize}`,
				url: `http://localhost:8082/news/selectNewsList?pageNum=${this.queryParams.pageNum}&pageSize=${this.queryParams.pageSize}`,
				// Headers: {
				// 	Authorization: getToken()
				// }
			};
			// setTimeout()
			axios(config).then(function (res) {
				// alert("查询成功")
				console.log("res.data.rows" , res.data.rows);
				that.total = res.data.total;
				
				that.newsList = res.data.rows;
				console.log(that.newsList);
				/* setTimeout(() => {
				}, 3000); */
				// this.newsList = [1, 2, 3];
				console.log("newsList:" + that.newsList);
			})
			.catch(function (error) {
				console.log(error);
				// alert("请求失败，请联系开发人员");
			})

		},
		async getList3() {
			const resp = await listNews({pageNum: 1, pageSize: 8});
			this.newsList = resp.rows;
			for (const news of this.newsList) {
				if (news.title.length > 34) {
					// console.log("news.title.length > 14");
					const newsSub = news.title.substring(0, 24);
					news.title = newsSub + "...";
				}
			}

		},
		getList2() {
			const that = this;
			var config = {
				method: "get",
				// url: `http://localhost:8080/news/selectNewsList?pageNum=1&pageSize=5`,
				// url: `http://localhost:8082/news/selectNewsList?pageNum=1&pageSize=5`,
				url: `http://localhost:8082/prod-api/news/selectNewsList?pageNum=1&pageSize=5`,
			};
			axios(config).then(function (res) {
				that.newsList2 = res.data.rows;
				console.log(that.newsList2);
			})
		},
		async getList4() {
			const resp = await listNews({pageNum: 1, pageSize: 5});
			this.newsList2 = resp.rows;
			this.total = resp.total;
			for (const news of this.newsList2) {
				if (news.title.length > 20) {
					const newsSub = news.title.substring(0, 20);
					news.title = newsSub + "...";
				}
			}

		},
		// 改变当前页的回调
		handleCurrentChange (val) {
			// this.currentPage = val
			this.queryParams.pageNum = val
		
			// this.pageData = this.queryByPage()
			this.newsList = this.getList()
		},
		// 实现分页的方法
		// queryByPage () {
		// 	// 起始位置 = (当前页 - 1) x 每页的大小
		// 	const start = (this.currentPage - 1) * this.pageSize
		// 	// 结束位置 = 当前页 x 每页的大小
		// 	const end = this.currentPage * this.pageSize
		
		// 	return this.tableData.slice(start, end)
		// }
		// 查看资讯详情
		handleNewsDetails(id) {
			this.$router.push({path: '/news/details', query: {id: id}});
		},
		async funyuming(msg) {
			const that = this;
			// console.log(store.state.user)
			// console.log("store.state.user:" + store.state.user)
			if (msg === '/login') {
				try {
					await this.$router.push(msg);
					// this.navList.push({ title: getUserPhone, url: "/keji" });
					// console.log("....................................", this.navList)
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/logout') {
				console.log('退出登录');
				// localStorage.removeItem('userId');
				// localStorage.removeItem('phone');
				removeUserPhone('UserPhoneKey');
				removeMemberName();
				removeUserId();
				removeUserState();
				sessionStorage.removeItem('memberNumber')
				sessionStorage.removeItem('memberPassword');
				sessionStorage.removeItem('memberName');
				sessionStorage.removeItem('memberMail');
				removeToken();
				// sessionStorage.removeItem('userId');
				// sessionStorage.removeItem('phone');
				console.log('removeUserPhone()函数执行了')
				try {
					// await this.$router.push('/');
					// location.reload()
					// await this.$router.push(`/login?redirect=${this.$route.fullPath}`);
					await this.$router.push(`/?redirect=${this.$route.fullPath}`);
					// that.$router.push('/');
					// location.reload()
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/member/memberInfo') {
				console.log("getUserId():", getUserId())
				await this.$router.push({ path: msg, query: { id: getUserId() } });
			} else {
				// console.log("this.phone=" + getUserPhone())
				// this.labelIndex = index;
				console.log("this.labelIndex:", this.labelIndex)
				console.log("index:", index)
				await this.$router.push(msg);
				// this.phone = getUserPhone();
			}
		},
		//选项切换
		async labelClick(index, url) {
			console.log(index, url, this.labelIndex);

			// this.$router.push(url)
			// this.labelIndex = index;
			if (index == 0) {
				this.$router.push('/')
				// this.labelIndex = 0;
			}
			if (index == 1) {

				this.$router.push('/allianceGeneralization')
				// this.labelIndex = 1;
			}
			if (index == 2) {
				// this.labelIndex = 2;
				this.$router.push('/newss')
			}
			if (index == 3) {
				// this.labelIndex = 3;
				this.$router.push('/college')
			}
			if (index == 4) {
				// this.labelIndex = 4;
				this.$router.push('/enterprise')
			}
			if (index == 5) {
				// this.labelIndex = 5;
				this.$router.push('/cooperation')
			}
		},
		handleIndex() {
			this.$router.push('/');
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
/* body,
h1,
h2,
h3,
h4,
h5,
h6, */
/* div, */
/* p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong, */
/* var,
em,
input,
textarea, */
/* select, */
/* option {
	margin: 0;
	padding: 0;
} */
/*去内外边距*/
/* html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
} */
/*字体 文本居左*/
/* ul {
	list-style: none;
} */
/*去ul li 小点*/
/* img {
	border: 0 none;
} */
/*img IE6 兼容问题*/
/* input,
select,
textarea {
	outline: 0;
} */
/*去除点击蓝框*/
/* textarea {
	resize: none;
	overflow: auto;
} */
/*锁定多行文本框大小 与文字超出出现滚动条*/
/* table {
	border-collapse: collapse;
	border-spacing: 0;
} */
/*表格边框合并 边框间距0*/
/* th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
} */
/*特殊文字粗细 样式显示正常*/
/* a {
	text-decoration: none;
} */
/*a标签去下划线*/
/* .clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
} */
/*清浮动*/
/* a {
	color: #000000;
} */
/*==========================Reset_End===========================*/

/* 这个样式没用 */
/* .youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
} */
/* 面包屑 */
/* .Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
} */
/* .Crumbs div {
	line-height: 46px;
} */
/* .Crumbs span {
	color: #000000;
} */
/* 按钮 */

/* .btn_cont {
	display: flex;
	width: 1200px;
	margin: 0 auto;
	margin-left: 666px;
} */
/* .btn_cont div {
	text-align: center;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
	margin-right: 18px;
	width: 185px;
	height: 40px;
	background: #f6f6f6;
	border-radius: 6px;
	cursor: pointer;
} */
/* .active {
	color: #ffffff !important;
	background: #23337f !important;
} */
/* 新闻列表 */
/* .item_cont {
	width: 1200px;
	margin: 0 auto;
	margin-top: 40px;
}
.item_cont1 {
	display: flex;
	width: 1200px;
	height: 252px;
	background: #ffffff;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item_cont1 img {
	margin: 32px 48px 32px 32px;
	width: 300px;
	height: 188px;
	border: dashed;
}
.item_cont1_text {
	width: 868px;
}
.item_cont1_text_top {
	margin: 45px 0 16px 0;
	width: 732px;
	height: 112px;

	border-bottom: 1px solid #eaeaea;
}
.item_cont1_text_top > h1 {
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 24px;
}
.item_cont1_text_top p {
	margin-top: 16px;
	width: 732px;
	height: 56px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 24px;
}
.item_cont1_text_btm {
	width: 732px;

	display: flex;
	justify-content: space-between;
} */
/* .item_cont1_text_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 28px;
} */
/* .item_cont1_text_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 28px;
} */
/* item2 */
/* .item2_cont {
	width: 1200px;
	margin: 0 auto;
	margin-top: 40px;
}
.item2_cont1 {
	margin-top: 30px;
	display: flex;
	width: 1200px;
	height: 252px;

	background: #f8f8f8;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
	margin: 32px 48px 32px 32px;
	width: 300px;
	height: 188px;
	border: dashed;
}
.item2_cont1_text {
	width: 868px;
}
.item2_cont1_text_top {
	margin: 45px 0 16px 0;
	width: 732px;
	height: 112px;

	border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;

	color: #3f3f3f;
	line-height: 24px;
}
.item2_cont1_text_top p {
	margin-top: 16px;
	width: 732px;
	height: 56px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 24px;
}
.item2_cont1_text_btm {
	width: 732px;

	display: flex;
	justify-content: space-between;
} */
/* .item2_cont1_text_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;

	color: #999999;
	line-height: 28px;
} */
/* .item2_cont1_text_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 28px;
} */
/* 翻页 */
/* .Pages_cont {
	margin: 40px 0;
	margin-left: 715px;
}
.Pages_cont ul {
	display: flex;
}
.Pages_cont ul li {
	text-align: center;
	line-height: 40px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
}
.Pages_cont ul li:hover {
	background: #23337f;
} */
.content {
	display: flex;
	width: 100%;
	height: 280px;
	.left {
		/* width: 40%; */
		width: 500px;
		height: 300px;
		margin-left: 200px;
		margin-top: 20px;
		p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: black;
			margin-top: 15px;
		}
	}
	.middle {
		width: 40%;
		height: 200px;
		/* .p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8f8f8f;
			margin-top: 10px;
			line-height: 30px;
		} */
	}
	.right {
		width: 20%;
		height: 200px;
		p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: black;
			margin-top: 15px;
		}
	}
}
.list {
	/* margin-top: 30px; */
	margin-top: 0px;
	margin-bottom: 5px;
	display: flex;
	width: 100%;
	/* height: 440px; */
	height: 310px;
	.left {
		width: 40%;
		height: 200px;
		margin-left: 200px;
		p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: black;
			margin-top: 15px;
		}
	}
	.middle {
		width: 40%;
		height: 200px;
		p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: black;
			margin-top: 15px;
			/* line-height: 30px; */
			text-align: center;
		}
	}
	.right {
		width: 20%;
		height: 200px;
		p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: black;
			margin-top: 15px;
		}
	}
}
/* .middle p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: black;
	margin-top: 15px;
} */
h2 {
	margin-left: 200px;
	margin-top: 20px;
}
#table2 {
	margin-left: 200px;
	margin-right: 200px;
	/* background-color: red; */
}
/* #el-pagination {
	margin-top: 20px;
	margin-bottom: 5px;
} */
/* ::v-deep .el-table--border th.el-table__cell,
::v-deep .el-table--group, .el-table--border{
  border: none !important;
} */
/* #image {
	width: 436px;
	height: 300px;
} */
#s1 {
	cursor: pointer;
}




.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.start {
	justify-content: start;
}

.around {
	justify-content: space-around;
}

.between {
	justify-content: space-between;
}

.label {
	font-size: 20px;
	color: #555555;
	margin-right: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;
	white-space: nowrap;
}

.label-index {
	width: 60px;
	height: 3px;
	background-color: #3a7ffb;
	border-radius: 3px;
	margin-top: 6px;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

/* 去掉表格底部的横线 */
.el-table::before {
height: 0px;
}
</style>
