// import Vue from 'vue'
// import Vuex from 'vuex'
// import user from './modules/user'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     title:'打造前程集团',
//     pageCount:8,
//     selected:0,
//   },
//   mutations: {   // mutations里只能写让改动立刻生效的代码，不能包含一部操作，会造成开发工具不准确
//   },
//   actions: {
//   },
//   modules: {
//     user
//   }
// })


import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import app from './modules/app'
// import settings from './modules/settings'
import user from './modules/user'
// import tagsView from './modules/tagsView'
// import dicts from './modules/dicts'
// import chat from './modules/chat'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    // app,
    // settings,
    user,
    // tagsView,
    // dicts,
    // chat
  },
  getters
})

export default store
