const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  // access_token: state => state.user.access_token,
  userInfo: state => state.user.userInfo,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  /** 字典数据 */
  // dicts: state => state.dicts.dicts,
  // dictsItem: state => state.dicts.dictsItem,
  // /** 网易IM */
  // nim:state => state.chat.nim,
  // chatList:state => state.chat.chatList,
  // usersList:state => state.chat.usersList,
  // friendsList:state => state.chat.friendsList,
  // sessionsList:state => state.chat.sessionsList,
  // message:state => state.chat.message,
  // sysnotice:state => state.chat.sysnotice,
  // broadcastMsgs:state => state.chat.broadcastMsgs,
  // unread:state => state.chat.unread,
  // addFriend:state => state.chat.addFriend,
  // imInit:state => state.chat.imInit
}
export default getters
