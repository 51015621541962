<template>
	<div class="youjiaopage_wrap">
		<!-- <daohang></daohang> -->
		<div class="flex align-center around">
			<!-- <div class="logo">
				<div style="height: 150px; width: 150px">
					<img style="height: 150px; width: 150px" :src="logoimg" class="inline-img" />
				</div>
				<div style="text-align: center; cursor: pointer;" id="i2" @click="handleIndex">
					职校联盟
				</div>
			</div> -->
			<div class="logo" style="display: flex; align-items: center;">
				<div style="height: 150px; width: 150px">
					<img style="height: 150px; width: 150px" :src="logoimg" />
				</div>
				<div style=" cursor: pointer; font-weight: 900; font-size: x-large;" id="i2" @click="handleIndex">
					职校联盟
				</div>
			</div>
			<div class="flex align-center">
				<div class="label" v-for="(item, index) in navList" :key="index" @click="labelClick(index, item.value)" style="cursor: pointer;">
					<div class="label-text" :style="labelIndex == index ? 'color:#333;' : ''">{{ item.label }}</div>
					<div class="label-index" v-if="labelIndex == index"></div>
				</div>
			</div>
			<div v-if="phone === null || phone === undefined" style="cursor: pointer;" @click="funyuming('/login')">会员登录/注册</div>
			<div v-if="phone">
				<el-dropdown v-if="phone" style="margin-top: -10px;">
					<!-- <span class="el-dropdown-link">
						{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span> -->
					<span v-if="memberName !== null && memberName !== undefined && memberName !== 'null' " class="el-dropdown-link">
						{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<span v-else class="el-dropdown-link">
						{{ phone }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="funyuming('/member/memberInfo')">会员信息</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/myPosted')">我发布的</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/myParticipated')">我参与的</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/member/finance')">财务管理</el-dropdown-item>
						<el-dropdown-item @click.native="funyuming('/logout')">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- <div style="display: flex; margin-left: 15%; margin-right: 10%;">
			<p style="flex: 1; margin-top: 15px;">欢迎进入职校联盟官网！</p>
			<div style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
				<el-input v-model="queryParams.name" placeholder="请输入关键字" @keyup.enter.native="handleQuery" size="small"
					style="width: 170px;"></el-input>
				<el-select v-model="searchType" placeholder="请选择搜索类型" size="small"
					style="width: 100px; margin-left: 10px;">
					<el-option v-for="item in searchTypes" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<router-link v-if="searchType === '1'" :to="{ path: '/member', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini"
						style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '2'" :to="{ path: '/meeting', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini"
						style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '3'" :to="{ path: '/project', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini"
						style="margin-left: 10px;">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '4'" :to="{ path: '/news', query: { name: queryParams.name } }">
					<el-button type="primary" icon="el-icon-search" size="mini"
						style="margin-left: 10px;">搜索</el-button>
				</router-link>
			</div>

			<div style="flex: 1; margin-top: 15px;">
				<p style="text-align: right;">服务热线：400123456</p>
			</div>
		</div> -->

		<div class="align-center around" style="
		  display: flex;
		  background-color: rgb(236,239,247);
		  padding: 20px;
		  margin-top: 20px;
		">
			<p style="">欢迎进入职校联盟官网！</p>
			<div style="text-align: center">
				<!-- <el-input style="width: 170px; margin-left: 50%"></el-input> -->
				<el-input v-model="queryParams.name" placeholder="请输入关键字" @keyup.enter.native="handleQuery" size="small"
					style="width: 370px"></el-input>
				<el-select v-model="searchType" placeholder="请选择搜索类型" size="small"
					style="width: 150px; margin-left: 10px">
					<el-option v-for="item in searchTypes" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<router-link v-if="searchType === '1'" :to="{ path: '/member', query: { name: queryParams.name, labelIndex: 1 } }">
					<el-button style="margin-left: 10px" type="primary" icon="el-icon-search" size="mini">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '2'" :to="{ path: '/meeting', query: { name: queryParams.name, labelIndex: 1 } }">
					<el-button style="margin-left: 10px" type="primary" icon="el-icon-search" size="mini">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '3'" :to="{ path: '/project', query: { name: queryParams.name, labelIndex: 1 } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px">搜索</el-button>
				</router-link>
				<router-link v-if="searchType === '4'" :to="{ path: '/news', query: { name: queryParams.name, labelIndex: 1 } }">
					<el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px">搜索</el-button>
				</router-link>
			</div>
			<div>
				<p>服务热线：{{ phoneNumber }}</p>
			</div>
		</div>
		<!-- <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2> -->
		<!-- <banner2 :bannerImg="Img1"></banner2> -->
		<banner2 :bannerImg="homeBanner"></banner2>
		<!-- 关于企业 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-r">
						<span> 职校联盟 </span>
						<br>
						<span> Vocational technical school Alliance </span>
						<p>
							职校联盟(Vocational technical school
							Alliance)，是由北京森泽坤仑教育科技有限公司联合社会众多职业院校、科研机构、企事业单位等共同发起的全国性非营利性的社会组织。旨在进一步推动校企合作、产教融合，探讨应用型人才培养新模式，为各职业院校、科研院所、企业搭建-一个全国性跨区域、跨行业、跨学科的职业教育高等协作平台。
						</p>
					</div>
					<div class="about-top-l">
						<div class="img-yinying"></div>
						<img src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png" alt="" />
						<!-- <img :src="logoimg" alt="" style="height: 234px; width: 234px; margin-top: 100px; margin-left: 100px;"/> -->
					</div>
				</div>
			</div>
		</div>
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l2">
						<span> 发展理念 </span>
						<br>
						<span> Development Concept </span>
						<p>
							职校联盟致力于与国内一流的高等职业教育院校和教育机构结盟发展，目前职校联盟拥有职业院校合作会员130多所、企业会员300余家(其中30%为世界五百强企业)，成功的为校企合作、教学研讨、职教的国际交流搭建了优质的服务交流平台，在此基础职校联盟成功的引进了“德国双元制职业教育模式”，其发展策略是创造性地拓展国际教育领域，为联盟所合作的职教院校引入国际权威的教学模式与教学思路，为所服务的企业能够接触到世界上一些第一流的人才而提供切实有效的优质人力资源渠道。
						</p>
					</div>
					<div class="about-top-r2">
						<div class="img-yinying"></div>
						<img src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png" alt="" />
						<!-- <img src="http://f.zxlmzs.com//jpg/2024/06/11/14_20240611152838A015.png" style="height: 200px; width: 316px; margin-top: 150px; margin-left: 100px;" alt="" /> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 企业文化 -->
		<div class="qywh" id="CultureInfo">
			<h1>主要业务</h1>
			<div class="qywh-item">
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 5.png" alt="" /></span> -->
					<p class="qywh-text">校企合作会议</p>
					<p class="qywh-text2">
						响应习近平总书记的号召，加强学校和企业合作，充分发挥校企双方优势，为职教类等院校人才输出作精准对接，职校联盟每年定期召开校企合作会议
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 10.png" alt="" /></span> -->
					<p class="qywh-text">联合办学</p>
					<p class="qywh-text2">
						职校联盟将依据学校、企业需求以市场为导向，共同开发多种合作模式
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 14.png" alt="" /></span> -->
					<p class="qywh-text">企业定制化培养</p>
					<p class="qywh-text2">
						订单式人才培养模式
						<br>
						“2+1”模式
						<br>
						“学工交替”模式
						<br>
						双定生模式
						<br>
						工学结合校企双向介入模式
						<br>
						企业为主合作办学
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 15.png" alt="" /></span> -->
					<p class="qywh-text">专业共建</p>
					<p class="qywh-text2">
						课程体系开发
						<br>
						师资培训共享
						<br>
						实验室建设
					</p>
				</div>
			</div>
			<div class="qywh-item">
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 5.png" alt="" /></span> -->
					<p class="qywh-text">招生就业</p>
					<p class="qywh-text2">
						解决专业陈旧无竞争力
						<br>
						解决线上招生效果差
						<br>
						解决线下招生团队力量薄弱问题
						<br>
						解决就业匹配不精准
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 10.png" alt="" /></span> -->
					<p class="qywh-text">海外研学</p>
					<p class="qywh-text2">
						职校联盟将带领院校走出去，学习全球各国的成功经验，促进职业院校跨国交流
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 14.png" alt="" /></span> -->
					<p class="qywh-text">新工科建设</p>
					<p class="qywh-text2">
						传统工科升级改造
						<br>
						现有理科生长工科
						<br>
						设立交叉复合专业
						<br>
						设立新兴特色专业
					</p>
				</div>
				<div class="qywh-item1">
					<!-- <span><img src="../assets/img/编组 15.png" alt="" /></span> -->
					<p class="qywh-text">网络推广</p>
					<p class="qywh-text2">
						利用网络的空间优势，互联网+能让职校所有教师全员参与交流，利用网络在学习空间进行交流，在职校联盟提供线上课堂、讲座等分享
					</p>
				</div>
			</div>
		</div>
		<div>
			<!-- <div style="text-align: center; font-size: 32px; font-weight: bold; color: rgba(68, 68, 68, 1);">联盟风采</div> -->
			 <h1 style="text-align: center; margin-top: 20px; margin-bottom: 20px;">联盟风采</h1>
			 <div style="display: flex; overflow-x: auto; width: 60%; margin-left: 20%; margin-right: 20%; margin-bottom: 80px;">
				<!-- <el-image style="width: 267px; height: 178px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/06/微信图片_20230420175109_20240606102219A001.png"></el-image> -->
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/06/微信图片_20230420175109_20240606102219A001.png"></el-image>
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/07/5000_20240607150721A011.jpg"></el-image>
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/14/98_20240614104413A074.png"></el-image>
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/06/1030_20240606222158A030.png"></el-image>
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/07/5001_20240607132257A008.png"></el-image>
				<el-image style="width: 267px; height: 178px; margin-left: 30px; flex-shrink: 0;" src="http://f.zxlmzs.com//jpg/2024/06/07/5004_20240607151033A012.png"></el-image>
			 </div>
		</div>
		<!-- 总裁寄语 -->
		<!-- <div class="President">
			<div class="President_content">
				<div class="President-title">
					<p>总裁寄语</p>
				</div>
				<div class="President-text">
					<p>
						打造前程集团公司自成立以来，始终保持战略定力，调整和优化产业结构，丰富产业布局，完成了金融、科技、电商、职教、幼教各业务板块的协同发展，以金融助力区域经济建设，以科技推进智慧建设，以产业带动专业建设，实现产教深度融合。<br />
						面对复杂多样、瞬息万变的市场形势，集团公司将保持既定的战略方向，发扬“脚踏实地、志存高远”的企业精神，坚持“责任、感恩、守正、创新、融合、发展”的企业理念，坚守“科技创新引领产业发展，产教融合培育技能人才”的企业使命，传承精髓，创新发展。未来，前程人要勇做守正传承的孺子牛、创新发展的拓荒牛、艰苦奋斗的老黄牛；立德树人守初心，守正创新担使命；有打硬仗、闯难关的坚韧，敢担当、善作为的干劲；脚踏实地，创新不辍，实现“汇才聚智，赋能产业”的愿景，开创集团公司发展的新高度。
					</p>

					<div class="President-text2">
						<span>执行总裁</span>
						<img src="../assets/img/杨刚.png" alt="" />
					</div>
				</div>
			</div>
		</div> -->
		<!-- 发展历程 -->
		<!-- <div class="DevelopmentHistory" id="history">
			<div class="DevelopmentHistory_cont">
				<h1>发展历程</h1>
				<div class="DevelopmentHistory_cont_img">
					<img
						src="../assets/img/leftjiantou2.png"
						class="DevelopmentHistory_cont_img1"
					/>
					<img
						src="../assets/img/rightjiantou.png"
						class="DevelopmentHistory_cont_img2"
					/>
				</div>
				<div class="DevelopmentHistory_cont_ul">
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
				</div>
			</div>
		</div> -->
		<!-- 底部 -->
		<a style="position: fixed; color: black; bottom: 20px; right: 20px;" href="#">
			<div style="width: 100px; text-align: center;">
				<img src="@/assets/img/backTop.png" alt="" width="50" height="50">
				<p>返回顶部</p>
			</div>
		</a>
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import { getUserPhone, getMemberName, getUserId, removeUserPhone, removeToken, removeMemberName, removeUserId, removeUserState } from '@/utils/auth'
export default {
	name: "App",
	data() {
		return {
			// h1: "集团概况",
			h1: "联盟概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
			// 查询参数
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				name: null
			},
			searchType: "4",
			searchTypes: [
				// {value: '1', label: '院校'},
				// {value: '2', label: '企业'},
				{ value: '1', label: '会员' },
				// {value: '3', label: '校企会'},
				{ value: '2', label: '校企会' },
				// {value: '4', label: '院校项目'},
				// {value: '5', label: '企业项目'},
				{ value: '3', label: '项目' },
				// {value: '6', label: '资讯动态'}
				{ value: '4', label: '资讯动态' }
			],
			labelIndex: 1,
			navList: [
				{ label: "首页", value: "/", },
				{ label: "联盟概况", value: "/allianceGeneralization", },
				{ label: "资讯动态", value: "/newss", },
				{ label: "院校库", value: "/college", },
				{ label: "企业库", value: "/enterprise", },
				{ label: "校企合作", value: "/cooperation", }
			],
			logoimg: require("../assets/img/ABUIABACGAAgvraU9QUomKOo1wMw9AM49AM.jpg"),
			homeBanner: localStorage.getItem('homeBanner'),
			phone: getUserPhone(),
			memberName: sessionStorage.getItem('memberName'),
			phoneNumber: localStorage.getItem('phoneNumber'),
		};
	},
	components: {
		daohang,
		footer2,
		banner2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
		/** 搜索按钮操作 */
		// handleQuery() {
		// 	if (this.searchType === "1" || this.searchType == "") {
		// 		this.$router.push('/member');
		// 	}
		// },
		// 回车搜索
		handleQuery() {
			if(this.searchType === "1") {
				this.$router.push({path: '/member', query: {name: this.queryParams.name, labelIndex: this.labelIndex}})
			}
			if(this.searchType === '2'){
                this.$router.push({ path: '/meeting', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
            if(this.searchType === '3'){
                this.$router.push({ path: '/project', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
            if(this.searchType === '4'){
                this.$router.push({ path: '/news', query: {name: this.queryParams.name, labelIndex: this.labelIndex} });
            }
		},
		async funyuming(msg) {
			const that = this;
			// console.log(store.state.user)
			// console.log("store.state.user:" + store.state.user)
			if (msg === '/login') {
				try {
					await this.$router.push(msg);
					// this.navList.push({ title: getUserPhone, url: "/keji" });
					// console.log("....................................", this.navList)
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/logout') {
				console.log('退出登录');
				// localStorage.removeItem('userId');
				// localStorage.removeItem('phone');
				removeUserPhone('UserPhoneKey');
				removeMemberName();
				removeUserId();
				removeUserState();
				sessionStorage.removeItem('memberNumber')
				sessionStorage.removeItem('memberPassword');
				sessionStorage.removeItem('memberName');
				sessionStorage.removeItem('memberMail');
				removeToken();
				// sessionStorage.removeItem('userId');
				// sessionStorage.removeItem('phone');
				console.log('removeUserPhone()函数执行了')
				try {
					// await this.$router.push('/');
					// location.reload()
					// await this.$router.push(`/login?redirect=${this.$route.fullPath}`);
					await this.$router.push(`/?redirect=${this.$route.fullPath}`);
					// that.$router.push('/');
					// location.reload()
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/member/memberInfo') {
				console.log("getUserId():", getUserId())
				await this.$router.push({ path: msg, query: { id: getUserId(), labelIndex: this.labelIndex } });
			} else {
				// console.log("this.phone=" + getUserPhone())
				// this.labelIndex = index;
				console.log("this.labelIndex:", this.labelIndex)
				// console.log("index:", index)
				// await this.$router.push(msg);
				await this.$router.push({ path: msg, query: {labelIndex: this.labelIndex}});
				// this.phone = getUserPhone();
			}
		},
		//选项切换
		async labelClick(index, url) {
			console.log(index, url, this.labelIndex);

			// this.$router.push(url)
			// this.labelIndex = index;
			if (index == 0) {
				this.$router.push('/')
				// this.labelIndex = 0;
			}
			if (index == 1) {

				this.$router.push('/allianceGeneralization')
				// this.labelIndex = 1;
			}
			if (index == 2) {
				// this.labelIndex = 2;
				this.$router.push('/newss')
			}
			if (index == 3) {
				// this.labelIndex = 3;
				this.$router.push('/college')
			}
			if (index == 4) {
				// this.labelIndex = 4;
				this.$router.push('/enterprise')
			}
			if (index == 5) {
				// this.labelIndex = 5;
				this.$router.push('/cooperation')
			}
		},
		handleIndex() {
			this.$router.push('/');
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}

.about {
	height: 500px;
	position: relative;
	margin: 100px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

/* .about-top-l { */
.about-top-r {
	position: absolute;
	/* left: 20px; */
	/* right: -110px; */
	right: -50px;
	top: 85px;
	/* width: 611px; */
	width: 511px;
	height: 442px;
}

.about-top-r>span {
	font-size: 32px;
}

.about-top-r>p {
	font-size: 16px;
	line-height: 40px;
}

.about-top-l2 {
	position: absolute;
	left: 20px;
	/* right: -110px; */
	/* right: -110px; */
	top: 85px;
	/* width: 611px; */
	width: 511px;
	height: 442px;
}

.about-top-l2>span {
	font-size: 32px;
}

.about-top-l2>p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

/* .about-top-r { */
.about-top-l {
	position: absolute;
	/* right: -110px; */
	left: 20px;
}

.about-top-l img {
	width: 487px;
	height: 517px;
}

.about-top-r2 {
	position: absolute;
	/* right: -110px; */
	right: -50px;
	/* left: 20px; */
}

.about-top-r2 img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	/* height: 598px; */
	/* height: 1000px; */
	height: 950px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	/* text-align: right; */
	/* margin-left: 1100px; */
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span>img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 10px;
	/* left: 10px; */
	/* width: 300px; */
	width: 288px;
	color: rgba(68, 68, 68, 1);
	/* font-size: 24px; */
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.qywh-text2 {
	position: absolute;
	top: 50px;
	left: 10px;
	/* text-align: center; */
	color: rgba(68, 68, 68, 1);
	/* font-size: 18px; */
	font-size: 14px;
	line-height: 28px;
	font-family: 微软雅黑;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title>p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text>p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}

.President-text2>span {
	position: absolute;
	left: 491px;
	top: 15px;
}

.President-text2>img {
	position: absolute;
}

/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}

.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}

.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}

.DevelopmentHistory_cont_img1 {
	float: left;
}

.DevelopmentHistory_cont_img2 {
	float: right;
}

.DevelopmentHistory_cont>h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}

.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}

.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}


.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.start {
	justify-content: start;
}

.around {
	justify-content: space-around;
}

.between {
	justify-content: space-between;
}

.label {
	font-size: 20px;
	color: #555555;
	margin-right: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;
	white-space: nowrap;
}

.label-index {
	width: 60px;
	height: 3px;
	background-color: #3a7ffb;
	border-radius: 3px;
	margin-top: 6px;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}
</style>
