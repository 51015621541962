import request from '@/utils/request'

// 查询资讯列表
export function listNews(query) {
  return request({
    url: '/news/selectNewsList',
    method: 'get',
    params: query
  })
}

// 根据咨询标题查询资讯列表
// export function listByTitle(query) {
//   return request({
//     url: '/news/list/like',
//     method: 'get',
//     params: query
//   })
// }

// 新增资讯
export function addNews(data) {
  return request({
    url: '/news',
    method: 'post',
    data: data
  })
}

// 更新资讯信息
export function updateNews(data) {
  return request({
    url: '/news',
    method: 'put',
    data: data
  })
}

// 查询新闻详情
export function selDetail(id) {
  return request({
    url: '/news/getNewsInfo?id=' + id,
    method: 'get'
  })
}
