import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import 'element-ui/lib/theme-chalk/index.css';
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'; // global css		// 登录界面的样式
// import Pagination from './components/Pagination'

// Vue.component('Pagination', Pagination)
// 引入基础css
import './assets/css/base.css';
import './icons'
import plugins from './plugins'
Vue.config.productionTip = false;

Vue.use(Element)
Vue.use(plugins)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

// title

// title
