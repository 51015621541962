<!-- <template>
	<div>
		<div class="wrap">
			<div class="logo">
				<img :src="logoimg" class="inline-img" />
				<span id="i2" @click="handleIndex">职校联盟</span>
			</div>

			<div class="nav">
				<ul>
					<li style="display: flex; flex-direction: column;" v-for="(item, index) in navList" :key="index" @click="funyuming(item.url, index)">
						{{ item.title }}
						<div class="label-index" v-if="labelIndex == index"></div>
					</li>
					<li @click="funyuming('/login')">
						<span style="margin-top: -10px; margin-left: 10px;" v-if="phone === null || phone === undefined">会员登录/注册</span>
					</li>
					<li>
						<el-dropdown v-if="phone" style="margin-top: -10px;">
							<span v-if="memberName !== null && memberName !== undefined && memberName !== 'null' " class="el-dropdown-link">
								{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<span v-else class="el-dropdown-link">
								{{ phone }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="funyuming('/member/memberInfo')">会员信息</el-dropdown-item>
								<el-dropdown-item @click.native="funyuming('/member/myPosted')">我发布的</el-dropdown-item>
								<el-dropdown-item @click.native="funyuming('/member/myParticipated')">我参与的</el-dropdown-item>
								<el-dropdown-item @click.native="funyuming('/member/finance')">财务管理</el-dropdown-item>
								<el-dropdown-item @click.native="funyuming('/logout')">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</li>
				</ul>
			</div>
			<div class="nav">
				<div class="flex label-view between align-center">
						<div class="label flex" v-for="(item, index) in navList" :key="index" @click="labelClick(index,item)">
								<div class="" :style="labelIndex == index ? 'color:#333;font-weight:bold' : ''">{{ item.title }}</div>
								<div class="label-index" v-if="labelIndex == index"></div>
						</div>
				</div>
			</div>
			<el-dropdown style="display: flex;">
				<span class="el-dropdown-link">
					{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>会员信息</el-dropdown-item>
					<el-dropdown-item>我发布的</el-dropdown-item>
					<el-dropdown-item>我参与的</el-dropdown-item>
					<el-dropdown-item>财务管理</el-dropdown-item>
					<el-dropdown-item>退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template> -->
<template>
	<div class="flex align-center around">
		<div class="logo" style="display: flex; align-items: center;">
			<div style="height: 150px; width: 150px">
				<img style="height: 150px; width: 150px" :src="logoimg" class="inline-img" />
			</div>
			<div >
				<div style=" cursor: pointer; font-weight: 900; font-size: x-large;" id="i2" @click="handleIndex">
					职校联盟
				</div>
			</div>
		</div>
		<div class="flex align-center">
			<div class="label" v-for="(item, index) in navList" :key="index" @click="labelClick(index, item.value)" style="cursor: pointer;">
				<div class="label-text" :style="labelIndex == index ? 'color:#333;' : ''">{{ item.label }}</div>
				<div class="label-index" v-if="labelIndex == index"></div>
			</div>
		</div>
		<div v-if="phone === null || phone === undefined" style="cursor: pointer;" @click="funyuming('/login')">会员登录/注册</div>
		<div v-if="phone" style="">
			<el-dropdown v-if="phone" style="margin-top: -10px;">
				<!-- <span class="el-dropdown-link">
					{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span> -->
				<span v-if="memberName !== null && memberName !== undefined && memberName !== 'null' " class="el-dropdown-link">
					{{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<span v-else class="el-dropdown-link">
					{{ phone }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="funyuming('/member/memberInfo')">会员信息</el-dropdown-item>
					<el-dropdown-item @click.native="funyuming('/member/myPosted')">我发布的</el-dropdown-item>
					<el-dropdown-item @click.native="funyuming('/member/myParticipated')">我参与的</el-dropdown-item>
					<el-dropdown-item @click.native="funyuming('/member/finance')">财务管理</el-dropdown-item>
					<el-dropdown-item @click.native="funyuming('/logout')">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>
<script>
import store from '@/store';
import { getUserPhone, getMemberName, getUserId, removeUserPhone, removeToken, removeMemberName, removeUserId, removeUserState } from '@/utils/auth'
export default {
	name: "Nav",
	data() {
		return {
			navList: [
				{ label: "首页", value: "/", },
				{ label: "联盟概况", value: "/allianceGeneralization", },
				{ label: "资讯动态", value: "/newss", },
				{ label: "院校库", value: "/college", },
				{ label: "企业库", value: "/enterprise", },
				{ label: "校企合作", value: "/cooperation", }
			],
			// navList: [
			// 	{ title: "首页", url: "/", value: 1 },
			// 	{ title: "联盟概括", url: "/allianceGeneralization", value: 2 },
			// 	{ title: "资讯动态", url: "/newss", value: 3 },
			// 	{ title: "院校库", url: "/college", value: 4 },
			// 	{ title: "企业库", url: "/enterprise", value: 5 },
			// 	{ title: "校企合作", url: "/cooperation", value: 6 },
			// ],
			// navList2: [
			// 	{ title: memberName, url: "/keji" },
			// ],
			// logoimg: require("../assets/img/lALPDhmOw39u8LZszQFq_362_108.png_720x720q90g.jpg"),
			// ABUIABACGAAgvraU9QUomKOo1wMw9AM49AM.jpg
			logoimg: require("../assets/img/ABUIABACGAAgvraU9QUomKOo1wMw9AM49AM.jpg"),
			// userId:localStorage.getItem('userId'),
			// phone:localStorage.getItem('phone'),
			// userId:sessionStorage.getItem('userId'),
			// phone:sessionStorage.getItem('phone'),
			phone: getUserPhone(),
			// memberName: getMemberName(),
			memberName: sessionStorage.getItem('memberName'),
			labelIndex : 0
		};
	},
	created() {
		console.log("nav.vue:", this.memberName);
	},
	methods: {
		async funyuming(msg) {
			const that = this;
			// console.log(store.state.user)
			// console.log("store.state.user:" + store.state.user)
			if (msg === '/login') {
				try {
					await this.$router.push(msg);
					// this.navList.push({ title: getUserPhone, url: "/keji" });
					// console.log("....................................", this.navList)
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/logout') {
				console.log('退出登录');
				// localStorage.removeItem('userId');
				// localStorage.removeItem('phone');
				removeUserPhone('UserPhoneKey');
				removeMemberName();
				removeUserId();
				removeUserState();
				sessionStorage.removeItem('memberNumber')
				sessionStorage.removeItem('memberPassword');
				sessionStorage.removeItem('memberName');
				sessionStorage.removeItem('memberMail');
				removeToken();
				// sessionStorage.removeItem('userId');
				// sessionStorage.removeItem('phone');
				console.log('removeUserPhone()函数执行了')
				try {
					// await this.$router.push('/');
					location.reload();
					await this.$router.push(`/?redirect=${this.$route.fullPath}`);
					// that.$router.push('/');
					// location.reload()
				} catch (error) {
					console.log(error)
				}
			} else if (msg === '/member/memberInfo') {
				console.log("getUserId():", getUserId())
				await this.$router.push({ path: msg, query: { id: getUserId(), labelIndex: this.labelIndex } });
			} else {
				// console.log("this.phone=" + getUserPhone())
				// this.labelIndex = index;
				// console.log("this.labelIndex:", this.labelIndex)
				// console.log("index:", index)
				await this.$router.push({path: msg, query: {labelIndex: this.labelIndex}});
				// this.phone = getUserPhone();
			}
		},
		funRouter() {
			this.$router.push("/keji");
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		handleIndex() {
			this.$router.push('/');
		},
		//选项切换
		// labelClick(index, item) {
		// 		this.labelIndex = index;
		// 		if(index === 0) {
		// 			this.$router.push('/')
		// 		}
		// 		if(index === 1) {
		// 			this.$router.push('/allianceGeneralization')
		// 		}
		// 		if(index === 2) {
		// 			this.$router.push('/newss')
		// 		}
		// 		if(index === 3) {
		// 			this.$router.push('/college')
		// 		}
		// 		if(index === 4) {
		// 			this.$router.push('/enterprise')
		// 		}
		// 		if(index === 5) {
		// 			this.$router.push('/cooperation')
		// 		}
		// },
		//选项切换
		async labelClick(index, url) {
			console.log(index, url, this.labelIndex);

			// this.$router.push(url)
			// this.labelIndex = index;
			if (index == 0) {
				this.$router.push('/')
				this.labelIndex = 0;
			}
			if (index == 1) {

				this.$router.push('/allianceGeneralization')
				this.labelIndex = 1;
			}
			if (index == 2) {
				this.labelIndex = 2;
				this.$router.push('/newss')
			}
			if (index == 3) {
				this.labelIndex = 3;
				this.$router.push('/college')
			}
			if (index == 4) {
				this.labelIndex = 4;
				this.$router.push('/enterprise')
			}
			if (index == 5) {
				this.labelIndex = 5;
				this.$router.push('/cooperation')
			}
		},
	},
};
</script>
<style scoped>
.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.start {
	justify-content: start;
}

.around {
	justify-content: space-around;
}

.between {
	justify-content: space-between;
}

.label {
	font-size: 20px;
	color: #555555;
	margin-right: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;
	white-space: nowrap;
}

.label-index {
	width: 60px;
	height: 3px;
	background-color: #3a7ffb;
	border-radius: 3px;
	margin-top: 6px;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}
</style>