import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from "@/utils/ruoyi";
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver'
import router  from '@/router/index'    // 导入@/router/index中的默认导出的router，并重新命名为router
import { getUserId, removeUserPhone, removeToken, removeMemberName, removeUserId } from '@/utils/auth'

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
  // console.log("方法被拦截器拦截了")
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    // config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    // console.log(config.method, config.params)
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    // console.log(config.url)
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    if (requestSize >= limitSize) {
      console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
      return config;
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  // if(store.getters.tocken) {
  // if(getToken()) {
  //   console.log(getToken())
  //   config.headers['Authorization'] = getToken()
  // }
  return config
}, error => {
    // console.log("error:", error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // const code = res.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // console.log('响应拦截器拦截到的响应res：' + res)
    // console.log('响应拦截器拦截到的响应res：', res)
    // console.log(res.data)
    // if(!res.data.loginSuccess) {}
    // if(!res.data.loginSuccess && res.data.msg === null) {
      // console.log(getToken())
    // if(getToken() === null && res.data.msg === "短信发送失败") {
    //   // this.$message({
    //   //   message: '用户名或密码错误，请重新输入！',
    //   //   type: 'error'
    //   // })
    //   alert("用户名或密码错误，请重新登录！")
    //   router.push('/login')
    //   location.reload()
    //   // return res;
    //   // router.replace({ path: '/' });
    //   // store.dispatch('/login')
    //   // window.location.href = '@/views/login';
    // }
    if(res.data.msg === "没有该用户") {
    // if(res.msg === "没有该用户") {
      alert("没有该用户，请重新登录！")
      router.push('/login')
      location.reload()
    }
    if(res.data.msg === "密码错误") {
    // if(res.msg === "密码错误") {
      // alert("用户名或密码错误，请重新登录！")
      // this.$modal.msgError("用户名或密码错误，请重新登录！");
      // this.$message({
      //   message: '用户名或密码错误，请重新输入！',
      //   type: 'error'
      // })
      MessageBox.confirm('用户名或密码错误，请重新登录！', '系统提示', {confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning'}).then(() => {
        // location.href = '/login';
        router.push('/login');
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
      // router.push('/login')
      // router.push({name: "login"})
      // location.reload()
    }
    // if(res.data.msg === "短信验证码登录失败") {
    // // if(res.msg === "短信验证码登录失败") {
    //   alert("短信验证码失效或错误，请重新登录！")
    //   router.push('/login')
    //   location.reload()
    // }
    // if(res.data.msg === "短信验证码登录失败") {
    // // if(res.msg === "短信验证码登录失败") {
    //   alert("短信验证码失效或错误，请重新登录！")
    //   router.push('/login')
    //   location.reload()
    // }
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      // console.log("res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'");
      return res.data
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
          isRelogin.show = false;
          // console.log("重新登录");
          store.dispatch('LogOut').then(() => {
            // location.href = '/index';
          })
      }).catch(() => {
        isRelogin.show = false;
      });
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 900) {
      MessageBox.confirm('您输入的验证码错误或已过期，请重新输入或发送验证码再登录', '系统提示', {confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning'}).then(() => {
        // location.href = '/login';    // 该语句实现不了跳转
        // router.push('/login');
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
    } else if (code === 901) {
      MessageBox.confirm('您还未设置密码，请先用验证码登录！', '系统提示', {confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning'}).then(() => {
        // location.href = '/login';    // 该语句实现不了跳转
        // router.push('/login');
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
    } else if (code === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    } else {
      // console.log("res.data:", res.data)
      return res.data
    }
  },
  error => {
    // console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if(error.response.status === 401) {
      // this.$modal.msgError("您的登录状态已过期，请重新登录！");
      removeUserPhone('UserPhoneKey');
      removeMemberName();
      removeUserId();
      sessionStorage.removeItem('memberNumber')
      sessionStorage.removeItem('memberPassword');
      sessionStorage.removeItem('memberName');
      sessionStorage.removeItem('memberMail');
      removeToken();
      MessageBox.confirm('您还未登录或登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        // isRelogin.show = false;
        // store.dispatch('LogOut').then(() => {
          // location.href = '/login';
          // router.push('/login');
          // location.reload();
          router.replace({ path: '/login' });
        // })
      })
      .catch(() => {
      //   isRelogin.show = false;
          // location.reload();      // 这里在会员信息那页有bug，点重新登录或者取消会一直弹您还未登录或登录状态已过期弹窗（当token过期后）
      });
      // await this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export default service
