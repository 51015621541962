import request from '@/utils/request'

// 获取网站总访问量和今日访问量
export function getTotalVisits() {
    return request({
        url: '/getTotalVisits',
        method: 'get'
    })
}

// 获取网站今日访问量
export function getDailyVisits() {
    return request({
        url: '/getDailyVisits',
        method: 'get'
    })
}