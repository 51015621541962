import { login2, login3, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken, getUserId, setUserId, setUserState, removeUserId, getUserPhone, setUserPhone, removeUserPhone, setMemberName, getMemberName, removeMemberName } from '@/utils/auth'

const user = {
  state: {
    // token: getToken(),
    id: '',
    name: '',
    email: ''
    // avatar: '',
    // roles: [],
    // permissions: []
  },

  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token
    // },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
    },
    SET_MAIL: (state, email) => {
      state.email = email
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_HOMEBANNER: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    // Login({ commit }, userInfo) {
    async Login({ commit }, userInfo) {
    // async Login({ commit }, userInfo) {
      // const username = userInfo.username.trim()
      console.log("Login方法被调用了")
      const phone = userInfo.phone.trim()
      const password = userInfo.password
      const code = userInfo.code;
      console.log(phone, password, code)
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      /* return new Promise((resolve, reject) => {
        console.log("进入了箭头函数中")
        // login2(phone, password, code, uuid).then(res => {
        console.log("userInfo.randomFlag:" + userInfo.randomFlag)
        if(userInfo.randomFlag === '0') {
          // 密码登录
          login2(phone, password).then(res => {
            // if(!res.loginSuccess) {
            //   // this.$message({
            //   //   message: '用户名或密码错误，请重新输入！',
            //   //   type: 'error'
            //   // })
            //   alert("用户名或密码错误，请重新登录！")
            //   return res;
            //   // this.$router('/login')
            // }
            if(res.loginSuccess) {
  
              setToken(res.token)
              console.log(res)
              // console.log(res.data)
              // setUserId(res.data.id)
              // setUserPhone(res.data.phone)
              setUserPhone(res.phone)
              // setMemberName(res.name)
              setMemberName(null)
              // localStorage.setItem('userId', JSON.stringify(res.data.id));
              // localStorage.setItem('phone', JSON.stringify(res.data.phone));
              // sessionStorage.setItem('userId', JSON.stringify(res.data.id));
              // sessionStorage.setItem('phone', JSON.stringify(res.data.phone));
              // commit('SET_TOKEN', res.token)
              // console.log(res.data)
              // commit('SET_ID', res.data.id)
              resolve()
            }
          }).catch(error => {
            console.log("error:" + error)
            reject(error)
          })
        }else {
          // 验证码登录
          login3(phone, code).then(res => {
            // if(!res.loginSuccess) {
            //   // this.$message({
            //   //   message: '用户名或密码错误，请重新输入！',
            //   //   type: 'error'
            //   // })
            //   alert("用户名或密码错误，请重新登录！")
            //   return res;
            //   // this.$router('/login')
            // }
            console.log(res)
            if(res != undefined && res.loginSuccess === true) {
  
              setToken(res.token)
              console.log(res)
              // console.log(res.data)
              // setUserId(res.data.id)
              // setUserPhone(res.data.phone)
              setUserPhone(res.phone)
              // localStorage.setItem('userId', JSON.stringify(res.data.id));
              // localStorage.setItem('phone', JSON.stringify(res.data.phone));
              // sessionStorage.setItem('userId', JSON.stringify(res.data.id));
              // sessionStorage.setItem('phone', JSON.stringify(res.data.phone));
              // commit('SET_TOKEN', res.token)
              // console.log(res.data)
              // commit('SET_ID', res.data.id)
              resolve()
            }
          }).catch(error => {
            console.log("error:" + error)
            reject(error)
          })
        }
      }) */
      console.log("userInfo.randomFlag:" + userInfo.randomFlag)
      if(userInfo.randomFlag === '0') {
        // 密码登录
        try {
          const res = await login2(phone, password);
          if(res.loginSuccess) {

            setToken(res.token)
            console.log(res)
            setUserPhone(res.phone)
            setUserId(res.id)
            setUserState(res.state);
            console.log(res.name);
            console.log(res.name !== undefined)
            setMemberName(res.name)
            commit('SET_NAME', res.name)
            commit('SET_PASSWORD', res.password)
            // commit('SET_MAIL', res.email)
            sessionStorage.setItem('memberName', res.name)
            sessionStorage.setItem("memberPassword", res.password )
            sessionStorage.setItem('memberMail', res.email)
            sessionStorage.setItem('orgType', res.orgType)
            // commit('SET_NAME', "梁彦文")
          }
        } catch (error) {
          console.log("error:" + error)
          reject(error)
        }
      }else {
        // 验证码登录
        try {
          const res = await login3(phone, code);
          console.log(res)
          if(res != undefined && res.loginSuccess === true) {
            setToken(res.token);
            console.log(res);
            setUserPhone(res.phone);
            setUserId(res.id);
            setUserState(res.state);
            setMemberName(res.name)
            commit('SET_NAME', res.name)
            commit('SET_PASSWORD', res.password)
            sessionStorage.setItem('memberName', res.name)
            console.log("sessionStorage.setItem('memberName', res.name):", sessionStorage.getItem("memberName"))
            sessionStorage.setItem("memberPassword", res.password )
            sessionStorage.setItem('memberMail', res.email)
          }
        } catch (error) {
          console.log("error:" + error)
        }
      }
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_ID', user.userId)
          commit('SET_NAME', user.userName)
          commit('SET_AVATAR', avatar)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        // logout(state.token).then(() => {
        logout(getToken()).then(() => {
          // commit('SET_TOKEN', '')
          // commit('SET_ROLES', [])
          // commit('SET_PERMISSIONS', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        // commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
